.top_head {
  flex-direction: column;
  align-items: baseline;
  width: 100% !important;
  height: 100% !important;
  position: relative;
  margin-bottom: 25px;
}
.top_head h2 {
  font-size: 26px;
  color: #e46666;
  font-weight: 600;
  padding: 10px;
}
.courseInformation .bodyTitle .bodyimagetitle .top_head span {
  max-width: 100%;
  width: 100%;
  height: auto;
}
.subscriptionPackage {
  width: 100%;
  justify-content: center;
}
.subscriptionPackage .monthlyPackage,
.subscriptionPackage .yearlyPackage {
  width: 50%;
  display: block;
  border: 1px solid #c2e2ff;
  min-height: 300px;
  margin: 0 15px;
  margin-bottom: 30px;
  background: #e6f3ff;
  transition: all 0.2s;
  max-width: 412px;
}

.subscriptionPackage .disabled {
  pointer-events: none;
  background: #e0e0e0;
}

.subscriptionPackage .monthlyPackage:hover,
.subscriptionPackage .yearlyPackage:hover,
.subscriptionPackage .yearlyPackage.Activesubcard,
.subscriptionPackage .monthlyPackage.Activesubcard {
  transform: scale(1.1);
  border: 1px solid #329fff;
}
.courseInformation .bodyTitle .subscriptionPackage .monthlyPackage h4,
.courseInformation .bodyTitle .subscriptionPackage .yearlyPackage h4 {
  color: #4e4e4e !important;
  padding: 0px 0px 0 25px;
  font-size: 18px !important;
  font-weight: 600 !important;
  width: 100%;
  text-transform: capitalize;
  display: block;
}
.courseInformation .bodyTitle .subscriptionPackage .checkmark {
  position: absolute;
  top: 5px;
  background: #c5c5c5;
}
.subscriptionPackage .Selcheckbox input:checked ~ .checkmark {
  background-color: #00b050;
}
.courseInformation
  .bodyTitle
  .subscriptionPackage
  .monthlyPackage
  .priceList
  ul
  li,
.courseInformation
  .bodyTitle
  .subscriptionPackage
  .yearlyPackage
  .priceList
  ul
  li {
  padding: 12px;
  color: #474646 !important;
  text-align: center;
  font-size: 21px;
  text-transform: capitalize;
}
.courseInformation
  .bodyTitle
  .subscriptionPackage
  .monthlyPackage
  .priceList
  ul
  li
  span,
.courseInformation
  .bodyTitle
  .subscriptionPackage
  .yearlyPackage
  .priceList
  ul
  li
  span {
  font-size: 64px;
  font-weight: 600;
  color: #1e79c9;
}
button.subscribebtn {
  background: #329fff;
  color: #fff;
  padding: 4px 10px;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 4px;
}

.checkoutProcess {
  width: 100%;
  justify-content: space-around;
}
.checkoutProcess .billingAddress,
.checkoutProcess .paymentMethod {
  width: 100%;
  display: block;
  min-height: 200px;
  background: #fff;
  padding: 0px 10px 10px 10px;
}
.billingAddress .inputField {
  flex-direction: column;
}
.billingAddress h4 {
  font-size: 18px;
  color: #3f3f3f;
  font-weight: 600;
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
}
.billingAddress p {
  font-size: 14px;
  color: #3f3f3f;
  font-weight: 400;
  padding-top: 5px;
}
.cardStyle label.Selcheckbox {
  font-size: 16px;
  padding-left: 25px;
  line-height: 1.2;
  color: #676767;
}
.billingAddress .cardStyle {
  width: 30%;
}

.cardStyle label.Selcheckbox img {
  max-width: 75px;
}
.SUbscriptionPreview,
.UsedCarddtl,
.BillingCardAdd {
  border: 1px solid #ccc;
  padding: 5px 10px;
}
.BillingCardAdd {
  min-height: 100px;
  overflow-y: auto;
  height: 145px;
}
.courseInformation
  .bodyTitle
  .subscriptionPackage
  .yearlyPackage
  .priceList
  ul
  li
  span {
  color: #1e79c9;
}
.subscriptionPackage .yearlyPackage {
  background: #e6f3ff;
  border-color: #c2e2ff;
}
.subscriptionPackage .yearlyPackage:hover {
  border-color: #329fff;
}
.SUbscriptionPreview span,
.UsedCarddtl span {
  font-size: 16px;
  color: #424242;
  font-weight: 600;
  width: 330px;
}
.billingAddress img {
  max-width: 350px;
  margin-left: 8px;
}
.SUbscriptionPreview h5,
.UsedCarddtl h5 {
  color: #6d6d6d;
  font-weight: 400;
  padding: 2px 0;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.robovicky img {
  max-width: 250px;
}
.missourplan ul li {
  font-size: 16px;
  margin-top: 10px;
  padding-left: 20px;
  position: relative;
}
.youloosesomething {
  border: 1px solid #329fff;
  padding-top: 0px;
}
.robovicky {
  background: #e3e3e3;
  padding: 10px 15px 0px;
}
.youloosesomething {
  border: 1px solid #ccc;
  padding-top: 0px;
  background: #e1f5fe;
}

.missourplan {
  padding: 20px;
}
.missourplan ul li:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #bbdefb;
  border-radius: 11px;
  top: 7px;
  left: 0;
  border: 3.5px solid #64b5f6;
}
.Upgradedowngerade .monthlyPackage.pointer {
  width: 31%;
}
.areyousureeSub {
  background: #f5f5f5;
  margin-top: 20px;
  padding: 30px 0;
  color: #1e79c9 !important;
}
.Upgradedowngerade.m-3 {
  margin-top: 30px;
  padding-top: 30px;
}
.enddateof h4.justify-content-center {
  color: #329fff !important;
  font-size: 20px !important;
}
.willmissedyou img {
  width: 300px !important;
}
/* Subscription cards */
.subscriptioncard {
  border: 1px solid #ccc;
  padding: 0px;
  max-width: 315px;
  border-top: 5px solid #ffa000;
  border-radius: 12px;
  margin: 0 15px;
  min-width: 315px;
  min-height: 400px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
}
.subscriptioncard:hover {
  transition: all 0.3s;
  background: #e3f2fd;
  border: 1px solid #ffa000;
  border-top: 5px solid #ffa000;
}
.form-switch label.form-check-label span:first-child {
  padding-left: 0;
  text-align: right;
}
.form-switch label.form-check-label span:last-child {
  padding-right: 0;
}
.SubsActivecard {
  transition: all 0.3s;
  background: #e3f2fd;
  border: 1px solid #ffa000;
  border-top: 5px solid #ffa000;
}
.subscriptioncard h4 {
  font-size: 24px;
  color: #424242;
  text-align: center;
  margin: 35px 0 0px;
}
.subscriptioncardtitle p.checkedCPicon {
  text-align: center;
  font-size: 16px;
  color: #000;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}
.cardpricewrap {
  padding-top: 80px;
}
.subscriptioncardtitle p img {
  background: #fff;
  border-radius: 50%;
  max-width: 40px;
}
.subscriptioncardtitle {
  padding: 10px 0;
}

.Subscriptioncardconlist {
  padding: 0px 15px 15px;
  width: 100%;
}

.subscriptioncardtitle span {
  font-size: 16px;
}
.subscriptioncardtitle h2 {
  font-size: 48px;
  margin: 0px 0 10px;
  text-align: center;
}
.Subscriptioncardconlist ul li svg {
  font-size: 14px;
  color: #a9a9a9;
}
.subscriptioncardtitle h2 sup {
  font-size: 36px;
}
.Subscriptioncardconlist ul li {
  font-size: 15px;
  margin-top: 10px;
}
.Subscriptioncardconlist ul li sup {
  font-size: 20px;
  line-height: 0;
  top: 0;
  font-weight: 600;
}
.Subscriptioncardconlist ul li img {
  width: 20px;
  margin-right: 8px;
}
.subscriptionChoose input {
  position: absolute;
  justify-content: center;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  margin-left: 0 !important;
  width: 65px !important;
  height: 1.8rem;
  margin-top: 6px !important;
}
.subscriptionChoose
  label.form-check-label.d-flex.w-90.m-auto.p-0
  span.saveuptomsg {
  background: #388e3c;
  margin-top: 0px !important;
  padding: 3px 8px;
  color: #fff;
  position: absolute;
  border-radius: 10px 0;
  top: 3px;
  width: auto;
  text-align: left;
  margin-left: 10px;
}
strong.disclamer {
  font-size: 14px;
  color: #232323;
  font-weight: 400;
}
.form-switch label.form-check-label span {
  padding: 0px 45px;
  font-size: 24px;
  width: 50%;
  display: initial;
  text-align: left;
}
.form-switch label.form-check-label span span {
  font-size: 14px;
}

.chooseSubscription {
  padding: 0px 0 0px;
}
.subscriptioncardtitle p {
  text-align: center;
  font-size: 16px;
  color: #000;
}
.chooseSubscription h3 {
  font-size: 37px;
  margin-bottom: 20px;
  color: #ffb74d;
  /* background: #FFB74D; */
  padding: 10px 0;
  border-radius: 39px;
  border: 2px solid #ffb74d;
  width: 389px;
  margin: 0px auto 20px;
}
.subscriptioncardtitle del {
  font-size: 30px;
  color: #989898;
}
.checkedCPicon svg.svg-inline--fa.fa-circle-check {
  font-size: 35px;
  color: #00b050;
}
.halfPagePOpup.subscriptionwrapcard .modal-body {
  background: #ffffff;
  width: 100%;
  margin: 0px auto;
  /* min-height: calc(92vh - 92px); */
}
.halfPagePOpup.subscriptionwrapcard .modal-content {
  background: #e3f2fd;
}
/* .chooseSubscription .form-switch .form-check-input{
  background-color: #00b050;
 } */
/* .chooseSubscription .form-switch .form-check-input:focus {
  background-image: url("../images/radiobtnicon.svg");
} */

.form-switch label.form-check-label span img {
  max-width: 16px;
  position: absolute;
  right: 90px;
  top: 10px;
}
.subscriptionwrapcard .yourexistingplan {
  font-size: 13px !important;
  color: #388e3c !important;
  width: 100%;
  margin: auto;
  padding: 1px 0;
  font-weight: 600;
  color: #fff !important;
  background: #388e3c;
}
.disableSubscard {
  background: #e9e9e9;
  opacity: 0.6;
  pointer-events: none;
}
.familydisclaimer {
  position: absolute;
  top: 8px;
  display: none;
  transition: all 0.3s;
  right: -67px;
  width: 145px;
}
.form-switch label.form-check-label img.familysub:hover + .familydisclaimer {
  display: block;
  /* border: 1px solid #90CAF9; */
  background: #e0e0e0;
  padding: 1px 5px 8px;
  border-radius: 4px;
  text-align: center;
  line-height: 1;
}
.chooseSubscription .form-check-input:checked {
  background-image: none;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.chooseSubscription .form-switch .form-check-input {
  background-image: url("../images/radiobuttoncircle.svg");
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.halfPagePOpup.subscriptionwrapcard .modal-body.checkoutbody {
  background: #ffffff;
  width: 100%;
  margin: 0px auto;
  border: 0px solid #96d3ff;
  border-radius: 0px;
  min-height: calc(92vh - 92px);
}
.halfPagePOpup.subscriptionwrapcard .modal-body.checkoutbody.checkoutpreview {
  min-height: calc(92vh - 32px);
}
.halfPagePOpup.subscriptionwrapcard .modal-body.checkouthanksyoumsg {
  min-height: calc(100vh - 92px);
}
.subscriptioncardwrap {
  padding: 0px 0 0px;
}
.SubsActivecard button.btn-blue.btn-login,
.subscriptioncard:hover button.btn-blue.btn-login {
  background-color: #005fb2;
}
.curruntplanstrip {
  text-align: right;
  background: #ffa000;
  position: absolute;
  left: -5.8px;
  padding: 4px 4px;
  top: 22px;
  transform: rotate(-47deg);
}
.curruntplanstrip.freeplan {
  top: 16px;
  left: -3px;
}
.curruntplanstrip p {
  font-size: 14px;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  min-height: 15px;
  height: 15px;
  font-style: italic;
}
.curruntplanstrip::after {
  content: "";
  position: absolute;
  right: -21px;
  height: 23px;
  border-left: 10px solid;
  border-left: 0px solid transparent;
  border-right: 23px solid transparent;
  border-bottom: 23px solid #ffa000;
  top: 0;
}
.subscriptioncardtitle p.savedprice {
  color: #ffa000;
  font-size: 18px;
  margin: 10px 0;
}
.curruntplanstrip::before {
  content: "";
  position: absolute;
  left: -23px;
  width: 0;
  height: 23px;
  border-left: 10px solid;
  border-left: 25px solid transparent;
  transform: rotate();
  border-right: 0px solid transparent;
  border-bottom: 23px solid #ffa000;
  top: 0px;
  transform: rotate(0deg);
}
.subscriptioncardtitle .Selcheckbox .checkmark:after {
  left: 19px;
  top: 8px;
  width: 18px;
  height: 36px;
  border: solid white;
  border-width: 0 7px 7px 0;
  transform: rotate(45deg);
}
.subscriptioncardtitle .checkmark {
  height: 60px;
  width: 60px;
  top: 45px !important;
  border: 1px solid #dbdbdb;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}
.expirydateedit {
  position: relative;
}
.expirydateedit .UserEditForm {
  right: 15px !important;
}
.halfPagePOpup.SchoolActivityPopup.subscriptionwrapcard button.close {
  top: 9px !important;
  line-height: 24px !important;
  padding: 1px 0px !important;
}
.subscription_error img {
  max-width: 300px !important;
}
.thanksmsg span {
  width: auto !important;
  height: auto !important;
}
.cancekSubsplan button {
  margin-bottom: 0 !important;
}
.cancekSubsplan button.btn-blue.btn-login.d-block.mb-5.cancelbutton.mb-0 {
  padding: 3px 0px 5px;
  font-size: 18px;
  background: #ffffff;
  cursor: pointer;
  color: #666666;
}
/* .cancelbutton svg.svg-inline--fa.fa-xmark {
  font-size: 18px !important;
  color: #666666 !important;
  border-color:#666666 !important;
} */
.areyouscansubs .modal {
  position: fixed;
  z-index: 99999999;
}
.cancelplan.w-100 {
  margin: 50px 0 30px;
  max-width: 660px;
}
.cancelplan p {
  font-size: 14px;
  cursor: pointer;
  color: #329fff;
  border-bottom: 1px solid;
  display: inline-block;
}
.halfPagePOpup.subscriptionwrapcard .modal-footer {
  position: sticky;
  bottom: 0;
}
.billingHistryTabl th,
.billingHistryTabl td {
  min-width: 25%;
  padding: 12px 10px;
  border: 1px solid #dfdee0;
}
.billingHistryTabl {
  padding: 20px;
}
.billingHistryTabl th {
  background: #427dcb;
  padding: 16px 10px;
}
.billingHistryTabl th:last-child {
  text-align: center;
}
.billingHistryTabl th h5 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.billingHistryTabl td p {
  font-size: 16px;
}
.billingHistryTabl tr:nth-child(2n + 1) {
  background: #f5faff;
}
p.viewinv {
  text-align: center;
  background: oklab(0.83 0 0 / 0.3);
  max-width: 64px;
  padding: 4px 0 5px;
  margin: auto;
  border-radius: 27px;
}
.subscriptionwrapcard .welcomscreenContent.areyouscansubs p {
  font-size: 20px;
}
.areyouscansubs svg.svg-inline--fa.fa-play {
  margin-top: 5px;
}
.subscriptionwrapcard .tabgrid ul li img {
  width: 22px;
}
.checkoutbody .parentdob span.checkmark {
  top: 13px;
}
.BillingCardAdd ul li p {
  font-size: 16px;
  padding-left: 10px;
  font-weight: 400;
  margin-bottom: 5px;
  display: flex;
}
.BillingCardAdd ul li p span {
  width: auto;
  margin-right: 3px;
}
.BillingCardAdd ul li p strong {
  font-weight: 400;
}
.BillingCardAdd ul li h4 {
  font-weight: 600;
  margin-bottom: 5px;
  color: #424242;
  font-size: 16px;
}
.BillingCardAdd ul li p a {
  font-size: 16px;
  margin: 0 0px;
}
.checkoutpreview .input-group label {
  color: #424242;
  font-size: 16px;
  margin-bottom: 0.4rem !important;
  font-weight: 400;
}
.checkoutpreview h4 img,
.checkoutpreview h4 svg {
  max-width: 20px;
  text-align: center;
}
.BillingCardAdd.mb-3.UsedCarddtl.Addrsbille {
  height: 65px;
  min-height: 65px;
}
.resetpointbtn {
  color: #329fff;
  font-size: 16px;
  line-height: 1;
  padding: 10px;
}
/* .UsedCarddtl.BillingCardAdd.mb-3 h5 {
  width: 100%;
} */
.downgradepopup .ScenecerelateddQuiz h4 {
  font-size: 18px;
}
.downgradepopup .subscriptioncardwrap {
  padding: 10px 10px 10px;
}
.downgradepopup .heading h2 svg.svg-inline--fa.fa-ballot-check.mr-2 {
  color: #1976d2;
}
.downgradepopup .ScenecerelateddQuiz {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.top_head h2 span a {
  font-size: 22px;
  color: #329fff;
}
.top_head h2 span {
  font-size: 22px;
  color: #424242;
}
span.price_Format {
  color: #00b050;
}
span.price_Format 
del {
  color: #000;
}
.promo_code .Discountcode input {
  opacity: .6;
  color: #ccc !important;
}
.UsedCarddtl.BillingCardAdd.mb-3 h5 {
  width: 100%;
}

.BillingCardAdd.mb-3.UsedCarddtl.Addrsbille h5 {
  width: 70%;
}
/* span.flexone.Discountcode button.btn-blue.btn-login.d-block.w-auto.back_button {
  padding: 4px 20px;
} */
.Discountcode input {
  max-width: 160px;
  height: 32px;
}
.subscriptioncard.mt-3.cardDisabled {
  opacity: .5;
  background: #f0f0f0;
  cursor: not-allowed !important;
}

.cardDisabled .subscriptioncardtitle .checkmark{
  pointer-events: none;
}
h3.SplNotiPlan  svg.svg-inline--fa.fa-triangle-exclamation.mr-2{
color: #e46666;
}
h3.SplNotiPlan {
  padding: 6px 15px 30px;
  color: #37474F;
  font-size: 17px;
  text-align: center;
  line-height: 1.5;
}