.paceSteps .QQtitle {
    text-align: left;
    padding-top: 7px;
    padding-left: 10px;
}
.paceSteps {
    margin: 15px;
}


.PaceModeSel label.Selcheckbox.m-0.ActiveQQst {
    min-width: 120px;
    padding: 16px 60px;
    font-size: 18px;
    border: 1px solid #51c190;
    border-bottom: 0;
    width: 100%;
    align-items: baseline;
    color: #232323;
}
.selectecPaceWeek{
    margin: 15px 0px 15px 0;
    /* border-bottom: 1px solid #51c190; */
    max-width: 200px;
    overflow: hidden;
    max-height: 205px;
    overflow-y: auto;
}
.newenrollepopup .selectecPaceWeek{
    border-bottom: 0;
}
.paceSteps .ScenecerelateddQuiz .signupType {
    max-width: 30%;
    width: 30%;
    align-items: flex-end;
    justify-content: center;
}
/* .seltimedaysit .selectecPaceWeek {
    margin-bottom: 70px;
} */
.PaceModeSel label.Selcheckbox.m-0.ActiveQQst span {
    font-size: 18px;
    padding-left: 3px;
    top: 10px;
}
.PaceModeSel .Selcheckbox .checkmark:after{
    left: 8px;
    top: 3px;
    width: 10px;
    height: 20px;
    border: solid white;
    border-width: 0 5px 5px 0;
    transform: rotate(45deg);
}
.paceSteps .paceseltitle img{
    max-width: 30px;
    z-index: 1;
    position: relative;
} 
.setup_two img.sceneQuestionicon{
    max-width: 30px !important;
}
.User_inisal img {
    max-width: 30px;
    z-index: 1;
    position: relative;
}
.userplan_democrd {
    padding: 20px 10px;
    border: 1px solid #ccc;
    /* background: #d3e3fd; */
    border-radius: 10px; 
}

.lerner_jounry_plan {
    margin: 20px 0;
}
.User_inisal{
    position: relative;
}
.User_inisal::before,.Plandetal_collapse:before,.Plandetal_collapse::before,.planheighCollaspe::before{
    content: "";
    position: absolute;
    left: -14px;
    width: 55px;
    top: 44px;
    background: url("../images/blueArrow.png");
   transform: rotate(90deg);
    background-position: center;
    height: 23px;
    background-repeat: no-repeat;
    background-size: contain;
    filter: grayscale(1);
}
.userPLan_start.course_destination {
    min-height: 58px;
}
.Plandetal_collapse:before,.planheighCollaspe::before{
    top: 58px;
    left: -9px;
    width: 63px;
    z-index: 11;
}


.Plandetal_collapse.Arrow_collesped::before,.planheighCollaspe.Arrow_collesped::before{
    display: none;
}
.lerner_jounry_plan.m-0 {
    position: relative;
}
.Contionur_Arrow{
    padding-left: 7px !important;
padding-top: 10px !important;
}
.flagchecked{
    min-width: 30px;
    margin-right: 15px;
}
.LPdestination_mark .User_inisal::before {
    content: "";
    position: absolute;
    top: 42px;
    /* left: -45px;
    width: 123px;
    top: 66px;
    
    height: 54px; */
}
.userPLan_start svg{
    font-size: 12px;
    color: #006dcc;
    padding-left: 5px;
    vertical-align: baseline;
}
.setuptwo_rPnl .plan_course_name {
    margin: 30px 0 30px;
}
.flexone.mb-5.plan_course_name.m-0 {
    margin-bottom: 4.5rem !important;
}
.ScenecerelateddQuiz .userPLan_start h4{
    margin-bottom: 0 !important;
}
.User_inisal.mr-2 {
    min-width: 30px;
    text-align: center;
    margin-right: 9px !important;
}
.selStartPlan .User_inisal::before {
    top: 44px;
}
.User_inisal.mr-2 svg.svg-inline--fa.fa-location-dot {
    font-size: 22px;
    margin-left: -3px;
    color: #a5a5a5;
}
span.startNOde {
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    left: 4px;
    background: #18c11f;
    top: -20px;
    transform: rotate(45deg);
    border: 2px solid #007d00;
    border-radius: 50%;
    box-shadow: 0px 0px 10px #1B5E20 inset;
}
.plan_course_name .User_inisal::before{
    top: 44px;
}
.signupType.rangetypeQuestion.gamesKCQuestion.textAreatypeQuestion.mb-2 ,.welcomeGmae{
    padding: 10px 5px;
    padding-bottom: 0 !important;
    border: 15px solid transparent;
    padding: 5px 3px;
    border-image: url("../images/gamesborder.png")  40 round;
    background-size: cover !important;
    background-position: center;
    background-repeat: repeat;
}

.gamesKCQuestion .Selcheckbox span.checkmark::after {
    border: 3px solid #fff;
    width: 8px;
    border-radius: 50%;
    background: #fff;
    top: 6px;
    left: 8px;
    height: 8px;
}
.gamesKCQuestion .Selcheckbox span.checkmark {
    width: 24px;
    height: 24px;
    box-shadow:0px -3px 0px #008a06 inset
}
/* .paceSteps .btn-login {
    padding: 8px 18px 8px 18px;
} */
.ScenecerelateddQuiz .signupType label p.palnquestiontitle {
    font-size: 21px;
    margin-bottom: 5px;
}
.plan_course_name {
    margin: 60px 0 22px;
}
.User_inisal.mr-2 svg {
    font-size: 22px;
    margin: auto;
    color: #a5a5a5;;
    z-index: 9;
    position: relative;
}
.course_destination p {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    background:#fff;
    /* border: 1px solid #F57C00;
    padding: 5px;
    border-radius: 39px;
    border-left: 6px solid #29B6F6;
    border-right: 6px solid #29B6F6; */
    line-height: 1.5;
}
p.Selected_paced {
    font-size: 16px;
}

.ScenecerelateddQuiz .lernarContinue h4 {
    font-size: 18px;
    margin: 26px 0;
    border: 1px solid green;
    background: #A5D6A7;
    padding: 5px 10px;
    /* text-shadow: 1px 2px #6f9d6f; */
}
.setup_two .ScenecerelateddQuiz .signupType .checkmark,.paceSteps span.checkmark  {
    top: 0px;
    height: 35px;
    width: 35px;
}
.ljpopups.setup_two .Selcheckbox .checkmark:after,.PaceModeSel .Selcheckbox .checkmark::after  {
    left: 9px;
    top: 4px;
    width: 13px;
    height: 22px;
    border-width: 0 6px 6px 0;
}
.PaceModeSel p,.paceSteps p{
    font-size: 18px;
    font-weight: 400;
}
.setup_two .ScenecerelateddQuiz .signupType label,p.onliner_Sel{
  padding-left: 50px;
  /* margin-bottom: 30px !important; */
}
.setup_two.ljpopups .ScenecerelateddQuiz h4 {
    font-size: 22px;
    margin-bottom: 30px !important;
    padding-left: 0px;
    line-height: 1.5;
}
.paceSteps span.checkmark {
    border: 1px solid #51c190;
    top: 7px;
    left: 14px;
}
.learning_plan_summry.ScenecerelateddQuiz  h4{
    font-size: 16px;
    font-weight: 400;
}
.summery_plan .form-switch .form-check-input {
    height: 1.3em;
    width: 3em;
}
.setup_five .DimTitleSlect h2{
    font-size: 24px !important;
    color: #000;
}
.summery_plan .form-switch label.form-check-label {
    padding-right: 41px;
    font-weight: 400;
    font-size: 16px;
}
.userPLan_start p.timint_date{
    font-size: 12px;
}

/* .welcomeGmae.p-3.mt-3 {
    background: #E3F2FD;
    margin: 10px;
    border: 15px solid transparent;
    padding: 5px 3px;
    border-image: url("../images/gamesborder.png") 40 round;
    background-size: cover !important;
} */
.wel_clisting_inst .welcomscreenContent ul{
    border: 0 ;
    border-left: 0;
    padding-bottom: 0;
    padding: 0 !important;
    background: transparent;
}
.wel_clisting_inst  .welcomscreenContent ul li svg {
    margin-top: 5px !important;
}
.lerining_planrightinfo{
    margin-right: 45px  !important;
}
.User_inisal img.helicoptericon{
 max-width: 35px;
 transform: rotate(135deg);
 left: -2px;
 z-index: 9;
 position: relative;
}
.LPdestination_mark .User_inisal.continue_journey::before,.LPrpcomponent:last-child .LPdestination_mark .User_inisal::before {
    content: "";
    position: absolute;
    /* top: 50px;
    width: 104px;
    left: -35px;
    top: 64px; */
}
h4.stripLeaner_plan {
    text-align: right;
}
h4.stripLeaner_plan a {
    margin-right: 5px;
    font-size: 16px;
    border-radius: 4px;
}
.setup_three .ScenecerelateddQuiz .signupType label p.palnquestiontitle {
    font-size: 20px !important;
    color: #232323;
}
.pathwayslearner.setup_three .ScenecerelateddQuiz .signupType label p.palnquestiontitle {
    font-size: 22px;
    color: #232323;
}
.setup_three .ScenecerelateddQuiz .signupType label p,p.onliner_Sel,.selec_Days_Custom .PaceModeSel p.onlinertxt {
    font-size: 14px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background: #fff;
    color: #6c6c6c;
}
.setup_two.ljpopups .ScenecerelateddQuiz h4 p{
    font-size: 16px !important;
    font-weight: 400;
}
.setup_two.setup_three .ScenecerelateddQuiz .signupType .checkmark{
    top: 0px;
    height: 30px;
    border-radius: 7px;
    width: 30px;
}

.ljpopups.setup_two.setup_three .Selcheckbox .checkmark:after {
    left: 9.5px;
    top: 2px;
    width: 12px;
    height: 20px;
    border-width: 0px 5px 5px 0;
}
.setup_two.setup_three.pathwayslearner .ScenecerelateddQuiz .signupType .checkmark {
    border-radius: 50%;
}
.setup_two.setup_three.ljpopups.setup_two.setup_three .Selcheckbox .checkmark:after{
left: 8px;
}
.setup_three .ScenecerelateddQuiz .signupType label .Courseporogresspopup p {
    color: #fff;
}

.setup_three .custom_pathways .Courseporogresspopup:after {
    top: -10px;
    left: 7%;
    border-bottom: 15px solid #666666;
}
.disabledchecked .checkmark{
    opacity: .5;
}
label.Selcheckbox.m-0.ActiveQQst.learnerPlan_sellebel.disabledchecked {
    opacity: .5;
    cursor: not-allowed;
}
.userPLan_start .DSCoursenameList p  {
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    color: #006dcc;
}
strong.pointer.Course_NamePland {
    color: #006dcc;
    white-space: nowrap;
    font-size: 16px;
}
.userPLan_start .statusImg{
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}
.LPremove_course svg{
    color: red;
    font-size: 14px;
}
.__react_component_tooltip p {
    color: #fff;
    overflow: auto;
    background: #666;
    display: inline;
}
.course_destination p div {
    display: inline;
}


/* learner plan setup 3 4 5 */
.DS_List .DS_List {
    padding: 0px 0 0 26px;
    width: 100%;
}
.DS_List i {
    font-size: 14px;
    padding: 7px 0px 0px 0px;
    display: inline-block;
    color: #939393;
    font-weight: 400;
}
.DS_List li {
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0 0px;
    width: 100%;
    font-weight: 600;
}
.DS_List ul {
    margin-left: 0px;
}
.DS_List svg.svg-inline--fa.fa-circle {
    color: #939393;
}
.DS_List img{
    max-width: 18px;
    margin-right: 5px !important;
}
.DS_List img.activeSetup{
    max-width: 30px;
}
/* ul.DSskillsList {
    margin-top: 10px;
} */
.DS_List .DS_List h4,.ScenecerelateddQuiz .DS_List h4,
.DS_List h4.flexone.pt-0 {
    margin-bottom: 0 !important;
    align-items: center;
    font-size: 16px;
    margin: 0px 0;
    font-weight: 400;
    padding-top: 10px;
    /* font-style: italic; */
}
.DS_List li svg.svg-inline--fa.fa-circle-check.mr-2 {
    color: #069d44;
}
.DS_List h4 svg.svg-inline--fa.fa-play.mr-2{
   
}
/* .DS_List ul.DSCoursenameList li {
    padding: 5px 0;
} */
.DS_List h4 svg.svg-inline--fa.fa-play.mr-2,.DS_List svg.svg-inline--fa.fa-hyphen{
    color: #939393;
    font-size: 14px;
    width: 12px;
    margin-right: 9px !important;
    margin-left: -2px;
}
.DS_List h4.dim_Name_att svg.svg-inline--fa.fa-play{
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background:#939393;
}
.DS_List li span{
    display: flex;
    align-items: center;
}
.ActivateStateLayer {
    /* background: #edf9ff; */
    border-radius: 3px;
    position: static !important;
}
.ActivateStateLayer:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #1E88E5;
    right: 15px;
    border-radius: 50%;
    animation-name: blinkbullet;
   animation-duration: 3s;  
   animation-iteration-count: infinite;
}
/* .DS_List {
    padding-left: 10px;
} */
li.dimensionNamehg{
    position: relative;
}
.dimensionNamehg:after {
    background: #edf9ff;
    border: 1px solid #90caf9;
    border-radius: 0px;
    content: "";
    height: 29px;
    left: -15px;
    border-left: 0;
    position: relative;
    position: absolute;
    top: 8px;
    width: calc(100% + 29px);
    z-index: -1;
    border-right: 0;
}

.DraftSelctionList {
    position: relative;
}
@keyframes blinkbullet {
0% {
    color: white;
  }

  50% {
    color: red;
  }
  100% {
    color: #1d103f;
  }
}
.DS_List li svg.svg-inline--fa.fa-circle-xmark {
    color: #363636;
    margin-left: 6px;
    font-size: 14px;
    opacity: 1;
}
.DS_List li svg {
    line-height: 1;
    vertical-align: text-bottom;
}
.lPDropdwnList svg.svg-inline--fa.fa-pencil.mr-2 {
    font-size: 18px;
    color: #329fff;
}
.multiplelening_dpdwn .lPDropdwnList svg.svg-inline--fa.fa-pencil.mr-2 {
    font-size: 16px;
    color: #329fff;
    width: auto;
}
.learnerplanOrderone {
    order: 1;
}

.setuptwo_rPnl {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.learner_Planrighpnl {
    order: 2;
    flex: 1 1;
    /* min-height: 71vh; */
    /* overflow-x: auto; */
}
.Contionur_Arrow{
    padding-bottom: 0px;
    padding-top: 34px !important;
}

.learner_planordr_three {
    position: sticky;
    bottom: 0px;
    order: 3;
    padding:8px 10px;
    background: #eee;
    min-height: 48px;
    z-index: 99;
    margin: 0 -15px;
}
.RightbarPannel.p-0.rightpannelSticky .heading.p-0.border-0 {
    position: sticky;
}
.learning_plan_summry.p-3.ScenecerelateddQuiz.p-0.setuptwo_rPnl {
    /* overflow: auto; */
    /* max-height: 79.4vh; */
}
.learner_pLan_dropdown ul.dropdown-menu li p{
    display: flex;
    align-items: center;
    color: #747474;;
}
label.Selcheckbox.m-0.ActiveQQst.learnerPlan_sellebel {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}
.learnerPlan_modelQue{
    margin-bottom: 30px !important;
}
.random_selection .learnerPlan_modelQue{
    margin-bottom: 15px !important;
}
.setup_five .random_selection .learnerPlan_modelQue{
    margin-bottom: 20px !important;
}
.setup_three .ScenecerelateddQuiz .signupType .coursedtlicons svg{
    color: #006dcc;
    font-size: 16px;
}
ul.dropdown-menu.dropdown-menu-end.lPDropdwnList.dropdwn_leaner_.show {
    left: -239px !important;
    width: 255px !important;
}
span.inprogress_Course {
    color: #000000;
    font-size: 11px;
    font-style: italic;
    background: #ff9333;
    border-radius: 11px;
    font-weight: 400;
    margin-left: 3px;
    line-height: 1.3;
    display: inline-block;
    padding: 0px 5px 1px;
}
span.inprogress_Course.complete_course{
    background: #3ec093;
}
.ljpopups .ScenecerelateddQuiz .signupType label span.recommendedTag {
    font-size: 16px !important;
    background: #e46666;
    color: #fff;
    padding: 4px 4px 4px;
    border-radius: 0px 10px;
    line-height: 1;
    margin: 0 2px 0 5px;
}
.new_Guidepoup {
    position: absolute;
    background: #eee;
    padding: 15px;
    max-width: 303px;
    top: 5px;
    border-radius: 4px 0 4px 4px;
    right: 38px;
    z-index: 999;
    background: rgb(50 159 255 / 100%);
    width: 300px;
    border-radius: 10px;
    padding: 20px;
}
.new_Guidepoup p {
    font-size: 14px;
    margin: 10px 0;
}
.new_Guidepoup:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 16px solid #329fff;
    border-bottom: 10px solid transparent;
    position: absolute;
    right: -11px;
    top: 2px;
}
.plan_startdate .input-group.calender .clenderIcon {
    right: 10px;
    top: 2px;
}
.welcome_new_poup .btn-login {
    padding: 10px 18px 10px 18px;
}
.Dimension_skill_tree h2 {
    margin-bottom: 20px;
    color: #424242;
    font-size: 22px !important;
    display: flex;
    align-items: center;
}
.Dimension_skill_tree .learnerPlan_modelQue {
    padding-left: 36px;
}
p.outCometxt {
    font-size: 12px;
    font-weight: 600;
}
.clenderIcon svg{
    font-size: 13px;
    color: #424242;
}
.DS_List ul .DSCoursenameList .longCourse_Name  span {
    white-space: nowrap;
    width: 315px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
}
.DS_List ul .DSCoursenameList .longCourse_Name {
    position: relative;
}
.DS_List ul .DSCoursenameList .longCourse_Name 
 svg.svg-inline--fa.fa-trash-can {
    position: absolute;
    top: 13px;
}
.DraftSelctionList {
    position: relative;
    padding-bottom: 50px;
}
.learnerplanOrderone {
    /* background: #def5ff; */
    /* border-bottom: 1px solid #b4deff; */
}
.Selected_paced p {
    padding: 3px 10px;
    border-radius: 5px;
    margin: 0px 0 0px;
    font-size: 16px;
    font-weight: 600;
    background: #ffffff;
}
.selplanListpace.Selected_paced p {
    font-size: 18px;
}
.Selected_paced p span {
    font-size: 16px;
    display: inline-block;
    font-weight: 400;
}
.minus_plus_icons img{
    max-width: 20px;
    vertical-align: baseline;
}
.User_inisal.mr-2 svg.svg-inline--fa.fa-rocket-launch{
    font-size: 26px;
    color: #7a7a7a;
}
div#AreasFocus {
    max-height: 250px;
    overflow: auto;
    width: 100%;
}
.ScenecerelateddQuiz .signupType label p.recummended,.parents_Support .signupType label p.recummended {
    width: 100%;
    position: absolute;
    top: 33px;
    font-size: 12px !important;
    background: none;
}
.menuList li.betaversion p.free_trail_text {
    font-size: 16px;
    color: #424242;
}
.menuList li.betaversion p a{
    font-size: 16px !important;
}
.helpAndlogout svg.svg-inline--fa.fa-circle-arrow-up {
    color: #e46666;
    font-size: 26px;
}
.courserefresh a,.courserefresh a:hover,.courserefresh a:focus{
    font-size: 16px;
}
.Multi_learner_plan {
    padding: 11px 10px;
    background: #e8f5ff;
    border-top: 1px solid #329fff;
    border-bottom: 1px solid #329fff;
    position: relative;
    /* position: sticky;
    top: 0; */
    /* z-index: 10; */
}
.Plandetal_collapse {
    max-height: 54vh;
    overflow: auto;
    padding: 0 10px;
}
.planheighCollaspe {
    padding: 0 10px;
    max-height: 58vh;
    overflow: auto;
    
}
.Multi_learner_plan svg.svg-inline--fa.fa-rocket-launch.mr-2 {
    font-size: 22px;
    color: #329fff;
    background: #e8f5ff;
    z-index: 1;
    position: relative;
}
.setuptwo_rPnl .Plandetal_collapse .LPrpcomponent:nth-child(1) .plan_course_name ,.setuptwo_rPnl .planheighCollaspe .LPrpcomponent:nth-child(1) .plan_course_name {
    margin-top: 7px;
}
.Selected_paced {
    margin: 4px 0 7px;
}
.DSCoursenameList p.pointer {
    font-weight: 400;
}
.MultiplanPacedesc{
    padding: 8px 10px 0 37px;
}
.Multi_learner_plan svg,.learner_pLan_dropdown svg {
    color: #424242;
    font-size: 17px;
}
.Multi_learner_plan p.multiplelening_dpdwn {
    position: absolute;
    right: 10px;
    top: 29px;
}
.duration_Time{
    padding-left: 50px !important;
    padding-right: 5px !important;
}
svg.svg-inline--fa.fa-calendar.ml-2.p-0{
color: #424242;
}
.rocket_img_{
    max-width: 21px;
}
.setup_three .ScenecerelateddQuiz .signupType .Dimension_skill_tree label .Courseporogresspopup p {
    color: #fff;
    display: block;
    background: #666666;
}
/* .padding65{
    padding-bottom: 20px;
} */
p.timint_date.mr-auto.ml-2.mt-1 {
    font-size: 15px;
}
.learner_plan #ScenecerelateddQuiz {
    padding: 0px 15px !important;
}
.recummenddedpln .planheighCollaspe {
    max-height: 58vh;
    overflow: auto;
}
.timezonelabel {
    padding: 10px 8px;
    border: 1px solid #ccc;
}
.timezonelabel ul.dropdown-menu.dropdown-menu-end.lPDropdwnList.show {
    width: 270px;
    left: -8px !important;
    top: 5px !important;
}
.PannelContent .timezonelabel ul.dropdown-menu.dropdown-menu-end.lPDropdwnList.show {
left: 5px !important;
}
.pleasewaitbtn{
    min-width: 125px !important;
}
.learner_pLan_dropdown ul.dropdown-menu li.pointer._Course.Summary svg{
width: 20px;
height: 20px;
font-size: 20px;

}
.selec_Days_Custom .PaceModeSel label.Selcheckbox.m-0.ActiveQQst {
    max-width: 20%;
    border: 0;
}
.selec_Days_Custom .selectecPaceWeek {
    align-items: center;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    max-height: none;
}
.paceSteps .ScenecerelateddQuiz.selec_Days_Custom  .signupType ,.selec_Days_Custom  .selectecPaceWeek{
    max-width: 100%;
}
.paceSteps .ScenecerelateddQuiz.selec_Days_Custom  .signupType .checkmark{
    border-radius: 5px;
    width: 30px;
    height: 30px;
}
 .selec_Days_Custom .PaceModeSel .Selcheckbox .checkmark::after {
    left: 8px;
    height: 20px;
    top: 2px;
    width: 11px;
    border-width: 0 5px 5px 0;
}
.selec_Days_Custom .PaceModeSel label.Selcheckbox.m-0.ActiveQQst.iamflexiblelabel {
    max-width: 100%;
    padding: 16px 50px 9px;
}
.schoolbubble_progress .NHProgresslistitem.IntellectualitemsNH {
    margin-top: 67px;
}
.selec_Days_Custom .PaceModeSel p.onlinertxt {
    padding-left: 39px;
}
.courseCircle{
    height: 25px;
    width: 25px;
    background-color: #4cb1e5;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: white;
  }
  .modulecircleColor {
      background-color: #006dcc !important;
  }
  .PaceModeSel label.Selcheckbox.m-0.ActiveQQst:last-child{
    border-bottom: 1px solid #51c190;
  }
.Acquisioton_survy_Plan img {
    max-width: 25px;
}
.Acquisioton_survy_Plan {
    border: 2px solid #329fff;
    padding: 17px 10px;
    margin-top: 10px;
    height: 70px;
    background: #e8f5ff;
}
.Multi_learner_plan p.multiplelening_dpdwn ul.dropdown-menu.lPDropdwnList.dropdown-menu-end.show {
    width: 220px;
}
.learner_pLan_dropdown ul.dropdown-menu.dropdown-menu-end li svg {
    font-size: 22px;
    margin-top: 0 !important;
    color: #329fff;
}
.learner_pLan_dropdown ul.dropdown-menu.dropdown-menu-end li svg.fa-trash-can{
color: red;
}
.parents_Support .congraulation_enroll .PaceModeSel label.Selcheckbox.m-0.ActiveQQst span.checkmark{
 border-radius: 50%;
}
.parents_Support .congraulation_enroll .selec_Days_Custom .PaceModeSel label.Selcheckbox.m-0.ActiveQQst .checkmark{
    border-radius: 5px;
    left: 0;
    top: 0px;
}
.parents_Support .congraulation_enroll .selec_Days_Custom .PaceModeSel label.Selcheckbox.m-0.ActiveQQst {
    padding:0px 40px 0;
}
.parents_Support .congraulation_enroll  .selec_Days_Custom .PaceModeSel p.onlinertxt {
    margin-left: 45px;
}
.Selected_paced p {
    background: transparent;
}
.input-group.full-Width-group.basic_details_form.pagebuttonStrip.position_sticky.bottom-0 {
    position: sticky;
}
.paceSteps h3.paceseltitle {
    font-size: 18px;
}

.setup_one .paceSteps p {
    font-size: 16px;
}

.PaceModeSel.mb-3 h3 {
    font-size: 18px;
}

.ScenecerelateddQuiz h3.text-nowrap.mb-3 {
    font-size: 18px;
}
.setup_one .paceSteps span.checkmark {
    height: 25px;
    width: 25px;
}
.setup_one  .PaceModeSel .Selcheckbox .checkmark::after {
    left: 7px;
    top: 2px;
    width: 8px;
    height: 16px;
    border-width: 0 4px 4px 0;
}
.setup_one .PaceModeSel label.Selcheckbox.m-0.ActiveQQst span.checkmark {
    top: 15px;
}
.setup_one  label.Selcheckbox.m-0.ActiveQQst {
    padding: 12px 60px 16px 50px;
}
.setup_one .PaceModeSel label.Selcheckbox.m-0.ActiveQQst span,.setup_one .paceSteps p.Mitual_days_sel {
    font-size: 17px;
}
.setup_one .paceSteps .input-group.calender p {
    font-size: 14px;
    color: #6c6c6c;
}
.setup_one .paceSteps .ScenecerelateddQuiz.selec_Days_Custom .signupType .checkmark {
    border-radius: 2px;
    width: 24px;
    height: 24px;
}
/* p.Mitual_days_sel strong {
    display: block;
    margin: 13px 30px;
} */

p.Mitual_days_sel {
    padding-left: 0px;
    padding-top: 20px;
    font-weight: 600;
}
span.user_planname {
    color: #006dcc;
}
.breadCrum li.Brumprofile ul li.AddLearner_bradcrumb {
    font-weight: 400;
    font-size: 14px;
    position: sticky;
    bottom: -5px;
    color: #747474;
}
/* .LeftbarPannel.p-0.justify-content-between .backpageStrip.flex {
    padding-top: 0;
} */

.challenges_itesms label.Selcheckbox.align-items-start.d-block {
    padding: 10px 45px;
    background: #f2f2f2;
    box-shadow:2px 2px 2px -1px #9FA8DA;
    margin-bottom: 8px;
    border-radius: 5px;
    color: #000;
    font-size: 18px;
}
.challenges_itesms .signupType {
    margin: 10px 0;
    /* background: #f5faff; */
    border: 0
}
.challenges_itesms .checkmark{
    position: absolute;
    top: 12px;
    left: 15px;
    border-radius: 3px;
    height: 20px;
    width: 20px;
    background-color: #bdb6b6;
}
.challenges_itesms .signupType h4 {
    background: #fdf1df;
    width: 100%;
    color: #fff;
    padding: 10px;
    margin-bottom: 7px !important;
    border: 1px solid #ffc062;
    border-radius: 5px
}
.Challenges_list h3 {
    font-size: 19px;
    line-height: 1.6;
}
.challenges_itesms .checkmark::after{
    top:4px !important;
}
.gid_plan_objective ul li{
    font-size: 15px;
    margin-bottom: 4px;
}
.plans_contents li {
    font-size: 14px;
    border: 1px solid #9c9a9a;
    width: 25%;
    padding: 11px;
    border-right: 0;
    border-bottom: 0;
}
.plans_contents ul.plan_schedule_ li:first-child {
    width: 75%;
}
span.sel_challenge_ {
    font-size: 14px;
    font-weight: 400;
}
.challenges_itesms .signupType h4 span {
    font-size: 18px !important;
}
.challenge_table_list ul li {
    font-size: 18px;
    width: 49%;
    background: #ccc;
    margin: 10px 0;
    padding: 12px 12px;
    box-shadow: 2px 2px 0 rgb(26 25 25 / 30%);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    color: #fff;
}
.challenge_table_list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.challenge_table_list ul li:nth-child(1) {
    background: #1cc0d9;
}
.challenge_table_list ul li:nth-child(2) {
    background: #a86ada;
}
.challenge_table_list ul li:nth-child(3) {
    background: #e56566;
}
.challenge_table_list ul li:nth-child(4) {
    background: #84b710;
}
.challenge_table_list ul li:hover{
    opacity: .8;
}
.scoreDescription img {
    max-width: 24px;
    margin-right: 8px;
}

.scoreDescription  li {
    font-size: 16px;
    margin: 8px 0;
}
.challenge_table.real_time_app {
    padding: 10px 0;
}

.challenge_table.real_time_app .realtymcard {
    padding: 10px;
    background: #1cc0d9;
    width: 24%;
    margin: 10px 0;
    border-radius: 5px;
    min-height: 200px;
}

.realtime_Applist {
    display: flex;
    justify-content: space-between;
}

.challenge_table.real_time_app .realtymcard h4 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    margin-top: 10px;
    font-size: 20px;
    min-height: 52px;
}

.challenge_table.real_time_app .realtymcard p {
    color: #fff;
    font-size: 16px;
    text-align: center;
}

.challenge_table.real_time_app .realtymcard:nth-child(2) {
    background: #84b710;
}
.challenge_table.real_time_app .realtymcard:nth-child(3) {
    background: #a86ada;
}

.challenge_table.real_time_app .realtymcard:nth-child(4) {
    background: #e56566;
}
.span4.widget-span.widget-type-cell {
    border: 1px solid #329fff;
    border-radius: 6px;
    padding: 0;
}
.Plan_card_ .helpvideos .helpvideosection img.view_video_plan {
    border: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 50px;
    background: #fff;
    border-radius: 50%;
}
.view_video_plan:after, .view_video_plan:before {
    align-items: center;
    animation-duration: 3s;
    bottom: 0;
    content: "";
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    border: 8px solid #243859;
}
.view_video_plan:before {
    animation-delay: .5s;
    animation-iteration-count: infinite;
    animation-name: video_trailer;
    animation-timing-function: cubic-bezier(.65,0,.34,1);
    border-radius: 100%;
}
.view_video_plan:after {
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-name: video_trailer;
    animation-timing-function: cubic-bezier(.65,0,.34,1);
    border-radius: 50%;
}
@keyframes video_trailer {
    0% {
        opacity: 1;
        transform: scale3d(.75, .75, 1);
    }
    100% {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}
.helpvideos .helpvideosection{
    position: relative;
}
